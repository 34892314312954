import type { GaVueComponent } from "@/common/vueUtils";
import { GaBadge } from "@/components/general/GaBadge";
import { useI18n } from "@/i18n/i18nSetup";
import type { SummaryIxStatusView, SummaryMetadata } from "@newgenerated/shared/schema";

export function SummaryCardMini(props: { summary: SummaryMetadata; link?: URL | null; params?: URLSearchParams; ixStatus?: SummaryIxStatusView; toggleBookmark?: () => void }): GaVueComponent {
  const { t } = useI18n(); // TODO: translation specific to summary language
  const summary = props.summary;
  const inactive = !summary.active && summary.previewAvailable;
  const completed = props.ixStatus === undefined ? false : props.ixStatus.consumedAt !== null;
  const bookmarked = props.ixStatus === undefined ? false : props.ixStatus.bookmarkedAt !== null;

  const link = ((): URL | null => {
    if (props.link === null) {
      return null;
    }
    const url = props.link === undefined ? new URL(`/summary/${summary.dataId}`, window.location.origin) : props.link;
    if (props.params !== undefined) {
      for (const [key, value] of props.params) {
        url.searchParams.append(key, value);
      }
    }
    return url;
  })();

  const coverUrl = ((): URL | null => {
    const url = new URL(summary.coverUri, window.location.origin);
    url.searchParams.append("s", "S");
    return url;
  })();

  const classes = ((): string[] => {
    const cls = ["summary-card-mini"];
    if (inactive) {
      cls.push("summary-card-mini--inactive");
    }
    if (completed) {
      cls.push("summary-card-mini--completed");
    }
    if (props.link === null) {
      cls.push("summary-card-mini--no-link");
    }
    return cls;
  })();

  return (
    <div class={classes}>
      {inactive ? (
        <div class="summary-card-mini__preview">
          <GaBadge variant="primary-dark">{t("browse:icon.inprogress")}</GaBadge>
        </div>
      ) : (
        <>
          {summary.rating !== null && summary.rating > 0 ? (
            <div class="summary-card-mini__rating" title={t("summary:rating.ga.title")}>
              <i class="ico-star-fill summary-card-mini__rating-icon"></i>
              {summary.rating}
            </div>
          ) : null}
          <div class="summary-card-mini__source caption">{t(`summary:source.${summary.sourceType}`)}</div>
          {props.toggleBookmark !== undefined ? (
            <div class="summary-card-mini__actions">
              <button onClick={props.toggleBookmark} class="btn btn-link">
                <i class={bookmarked ? "ico-bookmark-fill" : "ico-bookmark"}></i>
              </button>
            </div>
          ) : null}
        </>
      )}
      <div class="summary-card-mini__cover" aria-hidden={true}>
        {link === null ? (
          <div class="summary-card-mini__cover-img-wrapper">
            <img src="data:image/gif;base64,R0lGODlhBAADAIAAAGdwtWdwtSH5BAEKAAEALAAAAAAEAAMAAAIDjI9WADs=" data-src={coverUrl} alt="" class="summary-card-mini__cover-img lazyload" />
          </div>
        ) : (
          <a class="summary-card-mini__cover-img-link" href={link.href} tabindex="-1">
            <div class="summary-card-mini__cover-img-wrapper">
              <img src="data:image/gif;base64,R0lGODlhBAADAIAAAGdwtWdwtSH5BAEKAAEALAAAAAAEAAMAAAIDjI9WADs=" data-src={coverUrl} alt="" class="summary-card-mini__cover-img lazyload" />
            </div>
          </a>
        )}
      </div>
      <div class="summary-card-mini__title-box hyphenate">
        {link === null ? (
          <>{summary.title}</>
        ) : (
          <a href={link.href} class="summary-card-mini__title-link">
            {summary.title}
          </a>
        )}
      </div>
    </div>
  );
}
