import type { GaVueComponent } from "@/common/vueUtils";
import type { DeepReadonly } from "vue";
import type { ComponentUiState } from "@/components/getabstract-ai/utils/getabstractAiStore";
import { SelectedQuestion } from "@/components/getabstract-ai/uiFragments/SelectedQuestion";
import { AnswerCard, AnswerCardPlaceholder } from "@/components/getabstract-ai/uiFragments/Answer";
import { Feedback } from "@/components/getabstract-ai/uiFragments/Feedback";
import { References } from "@/components/getabstract-ai/uiFragments/References";
import { RelatedActionables } from "@/components/getabstract-ai/uiFragments/RelatedActionables";

export function Loading(props: {
  state: DeepReadonly<ComponentUiState>;
  giveFeedback: (questionUuid: string, isPositive: boolean) => void;
  toggleBookmark: (dataId: number, bookmarked: boolean) => void;
  feedbackIsPositive: (questionUuid: string) => boolean | null;
  summaryIsBookmarked: (dataId: number) => boolean;
}): GaVueComponent | null {
  if (props.state.status !== "LOADING" && props.state.status !== "STREAMING") {
    return null;
  }

  if (props.state.status === "STREAMING") {
    const { current, streamTokenCount } = props.state.value;
    const answer = current.answer.split(" ").slice(0, streamTokenCount).join(" ");

    return (
      <div class="d-grid gap-5">
        <SelectedQuestion question={current.question} isRelatedQuestion={props.state.value.history.length > 0} detectedLanguageLabel={current.detectedLanguageLabel} />
        <AnswerCard answer={answer} contextDocs={current.contextDocs} questionUuid={current.questionUuid} />
        <Feedback question={current.question} questionUuid={current.questionUuid} feedbackIsPositive={props.feedbackIsPositive(current.questionUuid)} giveFeedback={props.giveFeedback} showMailFeedbackLink={true} />
        <References contextDocs={current.contextDocs} answer={answer} questionUuid={current.questionUuid} toggleBookmark={props.toggleBookmark} summaryIsBookmarked={props.summaryIsBookmarked} />
        <RelatedActionables relatedActionables={current.relatedActionables} questionUuid={current.questionUuid} showExplanation={true} />
      </div>
    );
  }

  return (
    <div class="d-grid gap-5">
      <SelectedQuestion question={props.state.value.current.question} isRelatedQuestion={props.state.value.history.length > 0} detectedLanguageLabel={props.state.value.current.detectedLanguageLabel} />
      <AnswerCardPlaceholder />
    </div>
  );
}
