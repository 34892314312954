import { type DeepReadonly, defineComponent, type PropType } from "vue";
import { type ContentTypeProps, createStore, type ExtendedContentType, sampleQuestion, type SearchStoreActions, type SearchStoreState } from "@/components/search/fullSearchStore";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaButton } from "@/components/general/GaButton";
import { useI18n } from "@/i18n/i18nSetup";
import "./fullSearch.scss";
import type { QuestionAnswer } from "@/components/getabstract-ai/utils/getabstractAiStore";
import { getUsedReferencesByDataId } from "@/components/getabstract-ai/utils/getabstractAiAnswerUtils";
import { GaMarkdownContent, useSafeMarkdownRenderer } from "@/components/general/GaMarkdownContent";
import { createReferencesRenderer } from "@/components/getabstract-ai/uiFragments/Answer";
import { type DateTime, Language } from "@utils/type/type";
import GetAbstractAiLogo from "./resources/getabstractai-logo.svg";
import { GaChip } from "@/components/general/GaChip";
import { ActionableCardMini } from "@/components/actionable/ActionableCardMini";
import { SummaryCard } from "@/components/cards/SummaryCard";
import ChannelCardMini from "@utils/vue-migration/component/channel/ChannelCardMini.vue";
import CustomPageCardMini from "@utils/vue-migration/component/custompage/CustomPageCardMini.vue";
import type { ContentTypes } from "@/components/instant-search/_instant-search";
import type { FullSearchProps } from "@newgenerated/shared/schema";
import getCurrentLanguage = Language.getCurrentLanguage;

function ContentTypeChildren(props: { contentType: DeepReadonly<ContentTypeProps> }): GaVueComponent {
  const category = props.contentType;
  switch (category.discriminator) {
    case "SUMMARY":
      return (
        <div class="fullSearch__results-2">
          {category.items.slice(0, props.contentType.amountToShow).map((result) => (
            <SummaryCard
              summary={{
                ...result,
                contentItemId: BigInt(result.contentItemId),
                authors: [...result.authors],
                countriesIncluded: [...result.countriesIncluded],
                countriesExcluded: [...result.countriesExcluded],
                // Type casting until the new SummaryMetadata is being used
                activatedAt: result.activatedAt as DateTime<true>,
                activationTime: result.activationTime as DateTime<true>,
                audioActivatedAt: result.audioActivatedAt as DateTime<true>,
                coverModifiedAt: result.coverModifiedAt as DateTime<true>,
                modifiedAt: result.modifiedAt as DateTime<true>,
              }}
            />
          ))}
        </div>
      );
    case "CHANNEL":
      return (
        <div class="fullSearch__results-3">
          {category.items.slice(0, category.amountToShow).map((result) => (
            <ChannelCardMini channel={result} />
          ))}
        </div>
      );
    case "CUSTOMPAGE":
      return (
        <div class="fullSearch__results-3">
          {category.items.slice(0, category.amountToShow).map((result) => (
            <CustomPageCardMini customPage={result} />
          ))}
        </div>
      );
    case "ACTIONABLE":
      return (
        <div class="fullSearch__results-2">
          {category.items.slice(0, category.amountToShow).map((result) => (
            <ActionableCardMini actionable={{ actionableId: BigInt(result.actionableId), name: result.name, title: result.title, coverUri: result.coverUri }} />
          ))}
        </div>
      );
  }
}

function LoadingComponent(props: { large: boolean }): GaVueComponent {
  return (
    <div class="placeholder-wave mb-5">
      <div>
        <span class="placeholder col-3 mb-4" style="height:3.625rem"></span>
      </div>
      {props.large ? (
        <>
          <div class="fullSearch__results-2">
            <span class="placeholder" style="height: 10.1875rem"></span>
            <span class="placeholder" style="height: 10.1875rem"></span>
            <span class="placeholder" style="height: 10.1875rem"></span>
          </div>
        </>
      ) : (
        <>
          <div class="fullSearch__results-3">
            <span class="placeholder" style="height: 3.75rem"></span>
            <span class="placeholder" style="height: 3.75rem"></span>
            <span class="placeholder" style="height: 3.75rem"></span>
          </div>
        </>
      )}
    </div>
  );
}

function ContentType(props: { contentType: DeepReadonly<ContentTypeProps>; selectContentType: (contentType: ExtendedContentType) => void; loading: boolean }): GaVueComponent {
  const { t } = useI18n();
  if (props.loading) {
    return <LoadingComponent large={props.contentType.discriminator === "SUMMARY"} />;
  } else if (props.contentType.items.length > 0) {
    return (
      <div>
        <h2 class="mb-4">{props.contentType.title}</h2>
        <ContentTypeChildren contentType={props.contentType} />
        <div class="mt-3">
          <a type="button" class="btn btn-alt-primary btn-sm" onClick={() => props.selectContentType(props.contentType.discriminator)}>
            {t("search:page.results.showMore")}
          </a>
        </div>
      </div>
    );
  }
  return <></>;
}

function AskGetAbstractComponent(props: { data: QuestionAnswer }): GaVueComponent {
  const { t } = useI18n();
  const usedReferencesByDataId = getUsedReferencesByDataId(props.data.answer, props.data.contextDocs);
  return (
    <div class="card fullSearch__askGetabstract">
      <div class="d-flex gap-3 mb-3">
        <img src={GetAbstractAiLogo} width="56px" height="56px" alt="getAbstract AI Logo" />
        <h3 class="h4">{t("search:page.askGetabstract.subtitle")}</h3>
      </div>
      <p class="fullSearch__bold">{props.data.question}</p>
      <div class="fullSearch__askGetabstract-answer--shrink">
        <GaMarkdownContent
          input={props.data.answer}
          defaultRenderer={useSafeMarkdownRenderer(() => (
            <></>
          ))}
          intermediateRenderers={[createReferencesRenderer(usedReferencesByDataId)]}
        />
      </div>
      <p class="fullSearch__bold">{t("search:page.askGetabstract.relatedQuestions")}</p>
      {props.data.relatedQuestions.map((question) => (
        <p class="fullSearch__askGetabstract-question">
          <a href={"/" + getCurrentLanguage() + "/ask-getabstract?question=" + encodeURIComponent(question)}>{question}</a>
        </p>
      ))}
      <p class="mt-4">{t("search:page.askGetabstract.otherQuestion")}</p>
      <GaButton href={"/" + getCurrentLanguage() + "/ask-getabstract"}>{t("search:page.askGetabstract.title")}</GaButton>
    </div>
  );
}

function SearchContentTabs(props: { selectContentType: (type: ExtendedContentType) => void; selectedContentType: ExtendedContentType; contentTypes: DeepReadonly<ContentTypeProps[]> }): GaVueComponent {
  const tabOrder: { [content in ContentTypes]: number } = {
    CHANNEL: 1,
    SUMMARY: 2,
    ACTIONABLE: 3,
    CUSTOMPAGE: 4,
  };
  const { t } = useI18n();
  return (
    <div class="d-flex gap-3 flex-wrap">
      <GaChip active={props.selectedContentType === "ALL"} changeHandler={() => props.selectContentType("ALL")} value="ALL" type="radio" name="contentTypeTabs">
        {t("search:page.results.topResults")}
      </GaChip>
      {[...props.contentTypes]
        .sort((a, b) => tabOrder[a.discriminator] - tabOrder[b.discriminator])
        .filter((contentType) => contentType.items.length > 0)
        .map((contentType) => (
          <span>
            <GaChip changeHandler={() => props.selectContentType(contentType.discriminator)} active={contentType.discriminator === props.selectedContentType} value={contentType.discriminator} type="radio" name="contentTypeTabs">
              {contentType.title}
            </GaChip>
          </span>
        ))}
    </div>
  );
}

function Content(props: { state: DeepReadonly<SearchStoreState>; actions: SearchStoreActions }): GaVueComponent {
  const { t } = useI18n();
  return (
    <div class="row mt-4">
      <h1 class="mb-4">{t("search:page.title")}</h1>
      <div class="mb-5">
        <SearchContentTabs selectContentType={props.actions.selectContentType} selectedContentType={props.state.selectedContentType} contentTypes={props.state.contentTypeProps} />
      </div>
      <div class="col-lg-8 col-12">
        {props.state.contentTypeProps
          .filter((value) => props.state.selectedContentType === "ALL" || props.state.selectedContentType === value.discriminator)
          .map((contentType) => (
            <div class="mb-4">
              <ContentType selectContentType={props.actions.selectContentType} contentType={contentType} loading={props.state.loading} />
            </div>
          ))}
      </div>
      <div class="col-lg-4 col-12">
        <h2 class="mb-4">{t("search:page.askGetabstract.title")}</h2>
        <AskGetAbstractComponent data={sampleQuestion} />
      </div>
    </div>
  );
}

export const FullSearch = defineComponent({
  props: {
    fullSearchProps: {
      type: Object as PropType<FullSearchProps>,
      required: true,
    },
  },
  setup: (props) => {
    const store = createStore(props.fullSearchProps.query);
    return () => <Content state={store.state()} actions={store.actions} />;
  },
});
