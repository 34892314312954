import type { GaVueComponent } from "@/common/vueUtils";
import type { ActionableListView } from "@generated/model/actionableListView";
import { useI18n } from "@/i18n/i18nSetup";

export function ActionableCard(props: { actionable: ActionableListView; params?: URLSearchParams; extended?: boolean }): GaVueComponent {
  const { t } = useI18n();
  const actionable = props.actionable;
  const url = new URL(`${window.location.origin}/actionables/${actionable.name}/${actionable.actionableId}`);
  if (props.params !== undefined) {
    for (const [key, value] of props.params) {
      url.searchParams.set(key, value);
    }
  }
  return (
    <div class="card action-card">
      <span class="action-card__progress-badge">
        <i class="ico-half-progress-circle-fill actionable-step__progress-icon--started action-card__progress-badge-icon"></i>
        <i class="ico-check-circle-fill actionable-step__progress-icon--completed action-card__progress-badge-icon"></i>
      </span>
      <a href={url.href}>
        <div class="action-card__content">
          <img src={actionable.coverUri} class="card-img-top action-card__img" alt="" />
          <div class="action-card__body">
            <p class="caption action-card__caption">{t("actionable:page.a1.actionable")}</p>
            <h4 class="action-card__title">{actionable.title}</h4>
            {props.extended === true ? <div class="action-card__description">{actionable.description}</div> : null}
          </div>
        </div>
      </a>
    </div>
  );
}
