import { useI18n } from "@/i18n/i18nSetup";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaButton } from "@/components/general/GaButton";
import type { DeepReadonly } from "vue";

export function RelatedQuestions(props: { relatedQuestions: DeepReadonly<string[]>; chooseRelatedQuestion: (relatedQuestion: string) => void }): GaVueComponent | null {
  const { t } = useI18n();
  if (props.relatedQuestions.length === 0) {
    return null;
  }

  return (
    <div class="d-grid gap-3">
      <h4 class="m-0">{t("search:askGa.followUpQuestions.title")}</h4>
      <div class="getabstract-ai__related-questions">
        {props.relatedQuestions.map((question) => (
          <GaButton key={question} variant="link-slim" onClick={() => props.chooseRelatedQuestion(question)}>
            <span class="getabstract-ai__related-question">{question}</span>
          </GaButton>
        ))}
      </div>
    </div>
  );
}
