import type { BookmarkMap, ComponentInteractions, ComponentUiState, CurrentUiStatus, QuestionAnswer } from "@/components/getabstract-ai/utils/getabstractAiStore";
import type { GetabstractAiAnswer } from "@generated/model/getabstractAiAnswer";
import type { GetabstractAiAnswerStatus } from "@generated/model/getabstractAiAnswerStatus";
import type { DeepReadonly } from "vue";

export type Internal = {
  state: ComponentUiState;
  previousInteractions: ComponentInteractions;
};

export function aggregateBookmarkMap(list: GetabstractAiAnswer[]): BookmarkMap {
  return list
    .flatMap((entry) => entry.contextDocs)
    .reduce((acc, curr) => {
      acc.set(curr.dataId, curr.bookmarked);
      return acc;
    }, new Map<number, boolean>());
}

export function convertFinalStatus(status: GetabstractAiAnswerStatus): CurrentUiStatus {
  switch (status) {
    case "NO_DOCS_FROM_VECTOR_DB":
    case "NO_DOCS_AFTER_FILTER":
    case "NO_REFERENCE_IN_ANSWER":
      return "ERROR_NO_ANSWER";
    case "TIMEOUT":
    case "GENERAL_ERROR":
      return "ERROR_GENERAL";
    case "FINISHED":
      return "SUCCESS";
    case "PROCESSING":
      throw Error("`PROCESSING` is not expected to be final status");
  }
}

export function createEmptyQuestionAnswer(props: { question: string; questionUuid: string }): DeepReadonly<QuestionAnswer> {
  return {
    question: props.question,
    questionUuid: props.questionUuid,
    answer: "",
    contextDocs: [],
    detectedLanguageLabel: null,
    relatedActionables: [],
    relatedQuestions: [],
  };
}
