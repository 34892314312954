import type { GaVueComponent } from "@/common/vueUtils";
import { GaBadge } from "@/components/general/GaBadge";
import { useI18n } from "@/i18n/i18nSetup";
import type { SummaryIxStatusView, SummaryMetadata } from "@newgenerated/shared/schema";

export function SummaryCard(props: { summary: SummaryMetadata; link?: URL | null; params?: URLSearchParams; ixStatus?: SummaryIxStatusView; toggleBookmark?: () => void; delete?: () => void; compact?: boolean }): GaVueComponent {
  const { t } = useI18n(); // TODO: translation specific to summary language
  const summary = props.summary;
  const inactive = !summary.active && summary.previewAvailable;
  const completed = props.ixStatus === undefined ? false : props.ixStatus.consumedAt !== null;
  const bookmarked = props.ixStatus === undefined ? false : props.ixStatus.bookmarkedAt !== null;

  const link = ((): URL | null => {
    if (props.link === null) {
      return null;
    }
    const url = props.link === undefined ? new URL(`/summary/${summary.dataId}`, window.location.origin) : props.link;
    if (props.params !== undefined) {
      for (const [key, value] of props.params) {
        url.searchParams.append(key, value);
      }
    }
    return url;
  })();

  const coverUrl = ((): URL | null => {
    const url = new URL(summary.coverUri, window.location.origin);
    url.searchParams.append("s", "S");
    return url;
  })();

  const classes = (): string[] => {
    const cls = ["summary-card"];
    if (completed) {
      cls.push("summary-card--completed");
    }
    if (props.compact === true) {
      cls.push("summary-card--compact");
    }
    if (props.link === null) {
      cls.push("summary-card--no-link");
    }
    if (inactive) {
      cls.push("summary-card--inactive");
    }
    return cls;
  };

  return (
    <div class={classes()}>
      {inactive ? (
        <div class="summary-card__preview">
          <GaBadge variant="primary-dark">{t("browse:icon.inprogress")}</GaBadge>
        </div>
      ) : (
        <>
          <div class="summary-card__rating-source">
            {summary.rating !== null && summary.rating > 0 ? (
              <span class="summary-card__rating" title={t("summary:rating.ga.title")}>
                <i class="ico-star-fill summary-card__rating-icon"></i> {summary.rating}
              </span>
            ) : null}
            <span class="summary-card__source caption">{t(`summary:source.${summary.sourceType}`)}</span>
          </div>
          <div class="summary-card__actions">
            {props.toggleBookmark !== undefined ? (
              <button onClick={props.toggleBookmark} class="btn btn-link">
                <i class={bookmarked ? "ico-bookmark-fill" : "ico-bookmark"}></i>
              </button>
            ) : null}
            {props.delete !== undefined ? (
              <button onClick={props.delete} class="btn btn-link summary-card__deleter">
                <i class="ico-trash-bin"></i>
              </button>
            ) : null}
          </div>
        </>
      )}
      <div class="summary-card__cover" aria-hidden="true">
        {link === null ? (
          <div class="summary-card__cover-img-wrapper">
            <img src="data:image/gif;base64,R0lGODlhBAADAIAAAGdwtWdwtSH5BAEKAAEALAAAAAAEAAMAAAIDjI9WADs=" data-src={coverUrl} alt="" class="summary-card__cover-img lazyload" />
          </div>
        ) : (
          <a class="summary-card__cover-img-link" href={link.href} tabindex="-1">
            <div class="summary-card__cover-img-wrapper">
              <img src="data:image/gif;base64,R0lGODlhBAADAIAAAGdwtWdwtSH5BAEKAAEALAAAAAAEAAMAAAIDjI9WADs=" data-src={coverUrl} alt="" class="summary-card__cover-img lazyload" />
            </div>
          </a>
        )}
      </div>
      <div class="summary-card__titles hyphenate">
        {link === null ? (
          <h3 class="summary-card__title">{summary.title}</h3>
        ) : (
          <a href={link.href}>
            <h3 class="summary-card__title">{summary.title}</h3>
          </a>
        )}
        {props.compact !== true && summary.subtitle !== null && summary.subtitle.length > 0 ? <div class="summary-card__subtitle">{summary.subtitle}</div> : null}
      </div>
      <div class="summary-card__bibliographic">
        <div class="summary-card__authors">{summary.authorInfo}</div>
        {props.compact !== true ? <div class="summary-card__publisher">{summary.publisherInfo}</div> : null}
      </div>
    </div>
  );
}
