import { defineComponent, type PropType, ref } from "vue";
import "@/components/getabstract-ai/getabstractAi.scss";
import { TrendingQuestions } from "@/components/getabstract-ai/uiFragments/TrendingQuestions";
import type { Store } from "@/components/getabstract-ai/utils/getabstractAiStore";
import { createStore } from "@/components/getabstract-ai/utils/getabstractAiStore";
import { InitialForm } from "@/components/getabstract-ai/uiFragments/InitialForm";
import { Loading } from "@/components/getabstract-ai/uiFragments/Loading";
import { ErrorMessages, FailureAlerts } from "@/components/getabstract-ai/uiFragments/ErrorMessages";
import { CurrentQuestionAnswer } from "@/components/getabstract-ai/uiFragments/CurrentQuestionAnswer";
import { History } from "@/components/getabstract-ai/uiFragments/History";
import type { GaVueComponent } from "@/common/vueUtils";
import type { GetabstractAiProperties } from "@newgenerated/shared/schema";

export const GetabstractAi = defineComponent({
  props: {
    store: {
      type: Object as PropType<Store>,
      required: true,
    },
  },
  setup(props) {
    const { store } = props;
    const inputValue = ref(store.props.question ?? "");

    function submitTrendingQuestion(question: string): void {
      inputValue.value = question;
      store.actions.submitInitialQuestion(question, true);
    }

    return () => (
      <div class="d-grid gap-5">
        <div class="d-grid gap-4">
          <FailureAlerts failures={store.state().failures} dismissAlert={store.actions.dismissAlert} />
          <InitialForm
            inputValue={inputValue.value}
            updateInputValue={(newValue) => (inputValue.value = newValue)}
            language={store.props.language}
            submitInitialQuestion={store.actions.submitInitialQuestion}
            showAiDataProtectionDisclaimer={store.props.showAiDataProtectionDisclaimer}
          />
          {store.state().status === "INITIAL" ? <TrendingQuestions chooseTrendingQuestion={submitTrendingQuestion} /> : null}
        </div>
        <History state={store.state()} giveFeedback={store.actions.giveFeedback} toggleBookmark={store.actions.toggleBookmark} feedbackIsPositive={store.helpers.feedbackIsPositive} summaryIsBookmarked={store.helpers.summaryIsBookmarked} />
        <CurrentQuestionAnswer
          state={store.state()}
          giveFeedback={store.actions.giveFeedback}
          toggleBookmark={store.actions.toggleBookmark}
          chooseRelatedQuestion={store.actions.chooseRelatedQuestion}
          feedbackIsPositive={store.helpers.feedbackIsPositive}
          summaryIsBookmarked={store.helpers.summaryIsBookmarked}
        />
        <Loading state={store.state()} giveFeedback={store.actions.giveFeedback} toggleBookmark={store.actions.toggleBookmark} feedbackIsPositive={store.helpers.feedbackIsPositive} summaryIsBookmarked={store.helpers.summaryIsBookmarked} />
        <ErrorMessages state={store.state()} />
      </div>
    );
  },
});

export function GetabstractAiWidget(props: { getabstractAiProperties: GetabstractAiProperties }): GaVueComponent {
  const store = createStore(props.getabstractAiProperties);
  return <GetabstractAi store={store} />;
}
