import { assert } from "@utils/assertion";

import { formatTime, getRange, readState, updateState } from "./utils";
import "/src/components/audio-player/_audio-player.scss";
import { registerModule } from "@/Registry";

export const AUDIO_STATE = {
  HAVE_NOTHING: 0,
  HAVE_METADATA: 1,
  HAVE_CURRENT_DATA: 2,
  HAVE_FUTURE_DATA: 3,
  HAVE_ENOUGH_DATA: 4,
};

const SKIP_SECONDS = 15;

function checkState(view: {
  audioPlayer: Element;
  audio: HTMLAudioElement;
  volumeSlider: HTMLInputElement;
  seekSlider: HTMLInputElement;
  durationText: HTMLElement;
  currentTimeText: HTMLElement;
  muteButton: HTMLButtonElement;
  playbackRateText: HTMLElement;
  playbackRateDropdownButton: HTMLButtonElement;
  playbackRateAddButton: HTMLButtonElement;
  playbackRateSubButton: HTMLButtonElement;
  playButton: HTMLButtonElement;
  rewindButton: HTMLButtonElement;
  forwardButton: HTMLButtonElement;
}): void {
  const audioSrc = view.audioPlayer.getAttribute("data-ga-audio-player-url");
  const timestamp = view.audioPlayer.getAttribute("data-ga-audio-player-timestamp");
  assert(audioSrc !== null);

  if ((timestamp !== null || view.audio.src !== "") && !view.audio.src.endsWith(audioSrc)) {
    //initialize
    view.audio.src = audioSrc;
    if (timestamp !== null) {
      // autoplay activated
      view.audio.currentTime = parseFloat(timestamp);
      view.audio.play().catch(() => {
        /* error can be ignored, because nothing happens if autoplay doesn't start */
      });
    }
  }

  const state = readState();

  view.audio.volume = state.volume;
  view.audio.muted = state.muted;
  view.audio.playbackRate = state.playbackRate;

  view.volumeSlider.valueAsNumber = state.muted ? 0 : state.volume;
  view.playbackRateDropdownButton.textContent = `${state.playbackRate.toString()}×`;
  view.playbackRateText.textContent = `${state.playbackRate.toString()}×`;

  view.playbackRateSubButton.disabled = state.playbackRate <= 0.5;
  view.playbackRateAddButton.disabled = state.playbackRate >= 1.75;

  if (state.muted) {
    view.muteButton.classList.remove("ico-volume-2");
    view.muteButton.classList.add("ico-volume-mute");
    view.volumeSlider.style.backgroundSize = getRange(0, 0, 1);
  } else {
    view.muteButton.classList.add("ico-volume-2");
    view.muteButton.classList.remove("ico-volume-mute");
    view.volumeSlider.style.backgroundSize = getRange(state.volume * 100, 0, 100);
  }

  if (view.audio.paused) {
    view.playButton.classList.remove("ico-pause-fill");
    view.playButton.classList.add("ico-play-fill");
  } else {
    view.playButton.classList.remove("ico-play-fill");
    view.playButton.classList.add("ico-pause-fill");
  }

  switch (view.audio.readyState) {
    case AUDIO_STATE.HAVE_NOTHING:
      view.durationText.textContent = "";
      view.seekSlider.max = "0";
      view.currentTimeText.textContent = "";
      view.seekSlider.style.backgroundSize = "0% 100%";
      view.seekSlider.valueAsNumber = 0;
      view.rewindButton.disabled = true;
      view.forwardButton.disabled = true;
      view.seekSlider.disabled = true;
      break;
    case AUDIO_STATE.HAVE_METADATA:
    case AUDIO_STATE.HAVE_CURRENT_DATA:
    case AUDIO_STATE.HAVE_FUTURE_DATA:
    case AUDIO_STATE.HAVE_ENOUGH_DATA:
      view.durationText.textContent = formatTime(view.audio.duration);
      view.seekSlider.max = Math.round(view.audio.duration).toString();
      view.currentTimeText.textContent = formatTime(view.audio.currentTime);
      view.seekSlider.style.backgroundSize = getRange(Math.round(view.audio.currentTime), 0, view.audio.duration);
      view.seekSlider.valueAsNumber = Math.round(view.audio.currentTime);
      view.rewindButton.disabled = false;
      view.forwardButton.disabled = false;
      view.seekSlider.disabled = false;
      break;
    default:
      assert(false);
  }

  requestAnimationFrame(() => {
    checkState(view);
  });
}

export function initAudioPlayer(audioPlayer: Element): void {
  // only initialize and do stuff if the audio-player is not disabled (e.g. not logged in)
  if (audioPlayer.getAttribute("data-ga-audio-player-disabled") === "true") {
    return;
  }

  const audio = audioPlayer.querySelector<HTMLAudioElement>("[data-ga-audio-element]");
  const rewindButton = audioPlayer.querySelector<HTMLButtonElement>('[data-ga-audio-player-control="rewind"]');
  const playButton = audioPlayer.querySelector<HTMLButtonElement>('[data-ga-audio-player-control="play"]');
  const forwardButton = audioPlayer.querySelector<HTMLButtonElement>('[data-ga-audio-player-control="forward"]');
  const muteButton = audioPlayer.querySelector<HTMLButtonElement>('[data-ga-audio-player-control="mute"]');
  const volumeSlider = audioPlayer.querySelector<HTMLInputElement>('[data-ga-audio-player-control="volume"]');
  const playbackRateText = audioPlayer.querySelector<HTMLInputElement>("[data-ga-audio-player-playback-rate]");
  const playbackRateAddButton = audioPlayer.querySelector<HTMLButtonElement>('[data-ga-audio-player-control="playback-rate-add"]');
  const playbackRateSubButton = audioPlayer.querySelector<HTMLButtonElement>('[data-ga-audio-player-control="playback-rate-sub"]');
  const currentTimeText = audioPlayer.querySelector<HTMLElement>('[data-ga-audio-player-time="current"]');
  const durationText = audioPlayer.querySelector<HTMLElement>('[data-ga-audio-player-time="duration"]');
  const seekSlider = audioPlayer.querySelector<HTMLInputElement>('[data-ga-audio-player-time="slider"]');
  const detachButton = audioPlayer.querySelector<HTMLButtonElement>('[data-ga-audio-player-control="detach"]');
  const playbackRateDropdownButton = audioPlayer.querySelector<HTMLButtonElement>("[data-ga-audio-player-playback-rate-dropdown-button]");
  const playbackRateDropdownItems = audioPlayer.querySelectorAll("[data-ga-audio-player-playback-rate-dropdown-item]");

  if (
    audio === null ||
    rewindButton === null ||
    playButton === null ||
    forwardButton === null ||
    muteButton === null ||
    volumeSlider === null ||
    playbackRateText === null ||
    playbackRateAddButton === null ||
    playbackRateSubButton === null ||
    currentTimeText === null ||
    durationText === null ||
    seekSlider === null ||
    playbackRateDropdownButton === null
  ) {
    return;
  }

  playButton.addEventListener("click", () => {
    if (audio.paused) {
      const audioSrc = audioPlayer.getAttribute("data-ga-audio-player-url");
      assert(audioSrc !== null);
      if (!audio.src.endsWith(audioSrc)) {
        //initialize
        audio.src = audioSrc;
      }
      audio.play();
    } else {
      audio.pause();
    }
  });

  rewindButton.addEventListener("click", () => {
    let newCurrentTime;

    if (audio.currentTime - 15 <= 0) {
      newCurrentTime = 0;
    } else {
      newCurrentTime = audio.currentTime - SKIP_SECONDS;
    }

    audio.currentTime = newCurrentTime;
  });

  forwardButton.addEventListener("click", () => {
    let newCurrentTime;

    if (audio.currentTime + 15 >= audio.duration) {
      newCurrentTime = audio.duration;
    } else {
      newCurrentTime = audio.currentTime + SKIP_SECONDS;
    }

    audio.currentTime = newCurrentTime;
  });

  playbackRateAddButton.addEventListener("click", () => {
    updateState((state) => {
      return {
        version: state.version,
        muted: state.muted,
        volume: state.volume,
        playbackRate: state.playbackRate + 0.25,
      };
    });
  });

  playbackRateSubButton.addEventListener("click", () => {
    updateState((state) => {
      return {
        version: state.version,
        muted: state.muted,
        volume: state.volume,
        playbackRate: state.playbackRate - 0.25,
      };
    });
  });

  playbackRateDropdownItems.forEach((dropdownItem: Element) => {
    const playbackRate = dropdownItem.getAttribute("data-ga-audio-player-playback-rate-dropdown-item");
    if (playbackRate != null) {
      dropdownItem.addEventListener("click", () => {
        updateState((state) => {
          return {
            version: state.version,
            muted: state.muted,
            volume: state.volume,
            playbackRate: parseFloat(playbackRate),
          };
        });
      });
    }
  });

  volumeSlider.addEventListener("input", (e) => {
    const target = e.target as HTMLInputElement;
    const value = target.valueAsNumber;
    updateState((state) => {
      let muted = false;

      if (value <= 0) {
        muted = true;
      } else if (value > 0 && state.muted) {
        muted = false;
      }

      return {
        version: state.version,
        playbackRate: state.playbackRate,
        muted,
        volume: value,
      };
    });
  });

  muteButton.addEventListener("click", () => {
    updateState((state) => {
      return {
        version: state.version,
        volume: state.volume,
        muted: !state.muted,
        playbackRate: state.playbackRate,
      };
    });
  });

  seekSlider.addEventListener("change", (e) => {
    const target = e.target as HTMLInputElement;
    audio.currentTime = target.valueAsNumber;
  });

  seekSlider.addEventListener("input", (e) => {
    const target = e.target as HTMLInputElement;
    audio.currentTime = target.valueAsNumber;
  });

  audio.addEventListener("error", () => {
    playButton.disabled = true;
    rewindButton.disabled = true;
    forwardButton.disabled = true;
    seekSlider.disabled = true;
    volumeSlider.disabled = true;
    muteButton.disabled = true;
    playbackRateAddButton.disabled = true;
    playbackRateSubButton.disabled = true;
    playbackRateDropdownButton.disabled = true;
  });

  if (detachButton !== null) {
    detachButton.addEventListener("click", () => {
      const detachUrl = audioPlayer.getAttribute("data-ga-audio-player-detach-url");
      if (detachUrl !== null) {
        const url = new URL(detachUrl, window.location.origin);
        url.searchParams.append("ts", Math.floor(audio.currentTime).toString());
        window.open(url, "_blank", "innerWidth=800,innerHeight=361,popup=true");
        audio.pause();
      }
    });
  }

  checkState({ audioPlayer, audio, volumeSlider, seekSlider, durationText, currentTimeText, muteButton, playbackRateText, playbackRateDropdownButton, playbackRateAddButton, playbackRateSubButton, playButton, rewindButton, forwardButton });
}

registerModule("[data-ga-audio-player]", initAudioPlayer);
