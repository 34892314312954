import { hasProp } from "@utils/assertion";
import { isNonNullObject } from "@utils/objectUtils";

const CURRENT_VERSION = "2023-02-20";
export const LOCAL_STORAGE_KEY = "data-ga-audio-player-state";

// state is saved in the browser to remember the settings of the player
export type State = {
  version: string;
  volume: number;
  muted: boolean;
  playbackRate: number;
};

export function formatTime(duration: number): string {
  const totalMinutes = Math.floor(duration / 60);

  const seconds = Math.floor(duration % 60).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const minutes = (totalMinutes % 60).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  return `${minutes}:${seconds}`;
}

export function getRange(value: number, min: number, max: number): string {
  if (max - min <= 0.001) {
    return `0% 100%`;
  }
  const range = ((Math.round(value) - min) * 100) / (max - min);
  return `${range}% 100%`;
}

export function readState(): State {
  const stateStr = localStorage.getItem(LOCAL_STORAGE_KEY);
  const defaultState = {
    version: CURRENT_VERSION,
    volume: 1,
    muted: false,
    playbackRate: 1,
  } as const;
  if (stateStr === null) {
    return defaultState;
  }
  const parsed = JSON.parse(stateStr) as unknown;

  if (!isNonNullObject(parsed) || !hasProp(parsed, "version")) {
    return defaultState;
  }

  if (!isNonNullObject(parsed) || parsed.version !== CURRENT_VERSION) {
    return defaultState;
  }

  if (!hasProp(parsed, "volume")) {
    return defaultState;
  }

  const volume = parsed.volume;

  if (typeof volume !== "number") {
    return defaultState;
  }

  if (!hasProp(parsed, "muted")) {
    return defaultState;
  }

  const muted = parsed.muted;

  if (typeof muted !== "boolean") {
    return defaultState;
  }

  if (!hasProp(parsed, "playbackRate")) {
    return defaultState;
  }
  const playbackRate = parsed.playbackRate;

  if (typeof playbackRate !== "number") {
    return defaultState;
  }

  return { version: CURRENT_VERSION, volume, muted, playbackRate };
}

export function updateState(fn: (state: Readonly<State>) => void): void {
  const state = readState();
  const newState = fn(state);
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newState));
}
