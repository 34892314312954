/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { getHttpClient } from "@utils/httpClient";

/* tslint:disable:no-unused-locals */
// @ts-ignore
import { type FullSearchResults, parseFullSearchResults, renderFullSearchResults } from '../model/fullSearchResults';
// @ts-ignore
import type { Page } from "@utils/type/type";


/**
* Search for query
* @param query 
*/
export async function search(query: string): Promise<FullSearchResults> {
        return getHttpClient().request<FullSearchResults>({
            url: '/gaapi/search/fullSearch',
            method: 'GET',
            params: { query }
        })
        .then(response => {
               return parseFullSearchResults(response.data);
        });
}

