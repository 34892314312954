import type { GaVueComponent } from "@/common/vueUtils";
import type { TranslateFunction } from "@/i18n/i18nSetup";
import { GaButton } from "@/components/general/GaButton";
import type { DateTime } from "@utils/type/type";

function getProfileShareLink(actionableTitle: string, peerInsightProvidedAt: DateTime, certificateLink: string, certificateId: string, t: TranslateFunction): string {
  const certificateTitle = t("actionable:certificate.shareTitleProfile", [actionableTitle]);
  return (
    `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${encodeURIComponent(certificateTitle)}` +
    `&organizationId=110067&issueYear=${peerInsightProvidedAt.year}&issueMonth=${peerInsightProvidedAt.month}` +
    `&certId=${certificateId.substring(0, 20)}&certUrl=${encodeURIComponent(location.origin + certificateLink)}`
  );
}

function getPostShareLink(certificateLink: string): string {
  return "https://www.linkedin.com/sharing/share-offsite/?url=" + encodeURIComponent(location.origin + certificateLink);
}

function getCertificateLink(actionableId: bigint, certificateId: string, customerId: number, fancySharePage: boolean, format: "PDF" | "PNG"): string {
  return "/actionables/" + actionableId + "/certificate/" + certificateId + (fancySharePage ? "/share" : "") + "?customerId=" + customerId + "&format=" + format;
}

export function PdfPreview(props: { actionableId: bigint; actionableTitle: string; peerInsightProvidedAt: DateTime; certificateId: string; customerId: number; t: TranslateFunction; closeForm: () => void; isMapp: boolean }): GaVueComponent {
  const certificateLink = getCertificateLink(props.actionableId, props.certificateId, props.customerId, false, "PNG");
  return (
    <div>
      <p class="mt-2">{props.t("actionable:page.certificate.congratulation")}</p>
      <div class="actionable-certificate__container">
        <img class="img-fluid" src={certificateLink} alt={props.t("actionable:certificate.shareTitle", [props.actionableTitle])} />
      </div>
      {props.isMapp ? (
        <>
          <p>
            <i class="ico-info-circle" />
            {props.t("actionable:page.certificate.gotToWebsite")}
          </p>
        </>
      ) : null}
      <p class="d-flex gap-3 justify-content-center mt-4">
        <GaButton href={getPostShareLink(getCertificateLink(props.actionableId, props.certificateId, props.customerId, true, "PNG"))} target="_blank">
          <i class="ico-linkedin-logo" />
          &nbsp;
          {props.t("actionable:page.certificate.share.toLinkedin")}
        </GaButton>
        <GaButton href={getProfileShareLink(props.actionableTitle, props.peerInsightProvidedAt, certificateLink, props.certificateId, props.t)} target="_blank">
          <i class="ico-linkedin-logo" />
          &nbsp;
          {props.t("actionable:page.certificate.share.toLinkedinProfile")}
        </GaButton>
      </p>
      <p>
        <a
          class={["btn btn-alt-primary btn-sm", props.isMapp ? "disabled" : ""]}
          data-bs-toggle="tooltip"
          data-bs-title={props.t("actionable:page.certificate.gotToWebsite")}
          target="_blank"
          href={getCertificateLink(props.actionableId, props.certificateId, props.customerId, false, "PDF")}
          download={true}
          aria-disabled={props.isMapp}>
          <i class="ico-download" />
          &nbsp;
          {props.t("actionable:page.certificate.downloadPdf")}
        </a>
      </p>
    </div>
  );
}
