import { defineComponent, ref } from "vue";
import { TrendingQuestions } from "@/components/getabstract-ai/uiFragments/TrendingQuestions";
import { Feedback } from "@/components/getabstract-ai/uiFragments/Feedback";
import { SelectedQuestion } from "@/components/getabstract-ai/uiFragments/SelectedQuestion";
import { createParentComponent } from "@/common/vueUtils";
import { AnswerCard, AnswerCardPlaceholder } from "@/components/getabstract-ai/uiFragments/Answer";
import { References } from "@/components/getabstract-ai/uiFragments/References";
import { RelatedQuestions } from "@/components/getabstract-ai/uiFragments/RelatedQuestions";
import { RelatedActionables } from "@/components/getabstract-ai/uiFragments/RelatedActionables";
import { GaButton } from "@/components/general/GaButton";
import type { QuestionAnswer } from "@/components/getabstract-ai/utils/getabstractAiStore";
import { createStore } from "@/components/getabstract-ai/utils/getabstractAiStore";
import { GetabstractAi } from "@/components/getabstract-ai/GetabstractAi";
import type { ActionableMiniView } from "@generated/model/actionableMiniView";
import type { GetabstractAiAnswerContextDoc } from "@generated/model/getabstractAiAnswerContextDoc";
import { InitialForm } from "@/components/getabstract-ai/uiFragments/InitialForm";
import { ErrorMessage } from "@/components/getabstract-ai/uiFragments/ErrorMessages";
import { HistoryEntry } from "@/components/getabstract-ai/uiFragments/History";

const DEMO_TITLE_CLASSES = "demo-title mt-5 mb-2 p-1";

function bigIntAndMapReplacer(_key: string, value: unknown): unknown {
  return typeof value === "bigint" ? value.toString() : value instanceof Map ? Object.fromEntries(value) : value;
}

/**
 * Widget
 */
const Widget = defineComponent({
  setup() {
    const store = createStore({ question: null, language: "en", isTrendingQuestion: false, questionUuid: null, showAiDataProtectionDisclaimer: false });
    const showData = ref(true);
    const showUi = ref(true);

    return () => (
      <div class="px-3">
        <div class="container d-flex gap-3 my-3">
          <GaButton onClick={() => (showData.value = !showData.value)} variant={showData.value ? "primary-alt" : "link"}>
            {showData.value ? "hide data" : "show data"}
          </GaButton>
          <GaButton onClick={() => (showUi.value = !showUi.value)} variant={showUi.value ? "primary-alt" : "link"}>
            {showUi.value ? "hide ui" : "show ui"}
          </GaButton>
        </div>
        <div class="demo-widget my-5" data-data={showData.value} data-ui={showUi.value}>
          {showData.value ? (
            <div class="demo-data-output">
              <p>
                <strong>Data representation</strong>
              </p>
              <code>
                Interactions:
                <pre>{JSON.stringify(store._forDebugging.interactions(), bigIntAndMapReplacer, 2)}</pre>
                UI:
                <pre>{JSON.stringify(store.state(), bigIntAndMapReplacer, 2)}</pre>
              </code>
            </div>
          ) : null}
          {showUi.value ? (
            <div class="container">
              <GetabstractAi store={store} />
            </div>
          ) : null}
        </div>
        <style>{`
        .demo-widget {
          display: grid;
          gap: 1rem;
        } 
        
        .demo-data-output {
          background-color: var(--ga-gray-200);
          padding: 1rem;
          
          pre {
            white-space: pre-wrap;
            word-wrap: break-word;
          }
        }
        
        [data-data='true'][data-ui='true'] {
          grid-template-columns: 1fr 2fr;
        }
        `}</style>
      </div>
    );
  },
});

/**
 * Components
 */

type SimplifiedHistoryEntry = {
  answer: string;
  questionUuid: string;
  question: string;
  relatedQuestions: string[];
  relatedActionables: ActionableMiniView[];
  contextDocs: GetabstractAiAnswerContextDoc[];
};
const DUMMY_CONTENT: SimplifiedHistoryEntry = {
  answer:
    "To be a better leader, you must focus on self-improvement and continuous learning [19317]. Take risks, accept responsibility, and learn from your mistakes [23630]. Develop self-discipline, patience, and accountability [23630]. Define your leadership through your choices, attitude, and relationships [23630]. Be passionate about your work and inspire others [23630]. Listen actively and seek input from others [23630]. Focus on your strengths and delegate tasks that don't suit you [23630]. Stay grounded in reality and understand the long-term implications of your actions [40967]. Create a clear vision, communicate it effectively, and empower your team [28033]. Seek feedback and surround yourself with a diverse and capable team [28033]. Continually challenge your assumptions, ask questions, and stay curious [28033]. Finally, envision your desired future and take daily actions to achieve it [40967].",
  questionUuid: "84435e55-5153-499f-bcd5-5801a494ca5e",
  question: "How to be a better leader?",
  relatedQuestions: ["What are some effective leadership strategies?", "How can I improve my communication skills as a leader?", "What are some ways to build trust and rapport with my team?"],
  relatedActionables: [
    {
      title: "The way of life",
      name: "the-way-of-life",
      actionableId: BigInt(43),
      coverUri: "/actionable-img/43-the-way-of-life-1681198608000.jpg",
    },
    {
      title: "Retaining your workforce (testing progress)",
      name: "retaining-your-workforce-testing-progress",
      actionableId: BigInt(40),
      coverUri: "/actionable-img/40-retaining-your-workforce-testing-progress-1680615681000.jpg",
    },
    {
      title: "Networking: How to Build Business Relationships",
      name: "networking-how-to-build-business-relationships",
      actionableId: BigInt(1),
      coverUri: "/actionable-img/1-networking-how-to-build-business-relationships-1641572265000.jpg",
    },
    {
      title: "Tokio Hotel",
      name: "tokio-hotel",
      actionableId: BigInt(49),
      coverUri: "/actionable-img/49-tokio-hotel-1682341041000.jpg",
    },
    {
      title: "Test actionable for minio",
      name: "test-actionable-for-minio",
      actionableId: BigInt(63),
      coverUri: "/actionable-img/63-test-actionable-for-minio-1695027462000.jpg",
    },
  ],
  contextDocs: [
    {
      referenceNumber: 28033,
      dataId: 28033,
      title: "Every Day Is Friday",
      rating: 6,
      sourceTypeString: "Book",
      urls: {
        cover: "/summary-img/28033-JMHJLS0O.jpg",
        reference: "/summary/28033",
        list: "/summary/28033",
      },
      snippet: "A “Super Leader” Take time to meditate, think deeply and discover your “why.",
      bookmarked: false,
    },
    {
      referenceNumber: 19317,
      dataId: 19317,
      title: "Reinvent",
      rating: 7,
      sourceTypeString: "Book",
      urls: {
        cover: "/summary-img/19317-JQQOLCKW.jpg",
        reference: "/summary/19317",
        list: "/summary/19317",
      },
      snippet: "Self-ImprovementTo lead, you must be credible, “authentic,” “purposeful” and “connected.",
      bookmarked: true,
    },
    {
      referenceNumber: 40967,
      dataId: 40967,
      title: "9 Things a Leader Must Do",
      rating: 9,
      sourceTypeString: "Book",
      urls: {
        cover: "/summary-img/40967-KH3YTZE8.jpg",
        reference: "/summary/40967",
        list: "/summary/40967",
      },
      snippet: "Consider the future implications of your actions.",
      bookmarked: false,
    },
    {
      referenceNumber: 23630,
      dataId: 23630,
      title: "The Leadership Handbook",
      rating: 6,
      sourceTypeString: "Book",
      urls: {
        cover: "/summary-img/23630-JQP6870O.jpg",
        reference: "/summary/23630",
        list: "/summary/23630",
      },
      snippet: "Become a Strong LeaderAnyone can lead, but becoming a quality leader requires a lifetime of learning.",
      bookmarked: false,
    },
  ],
};

const DemoWrapper = createParentComponent((props) => {
  return <div class="demo-wrapper">{props.children}</div>;
});

const DemoInput = defineComponent({
  setup() {
    const questionInput = ref("");

    function submitInitialQuestion(): void {
      console.log(`'submitInitialQuestion' event fired. Current state: '${questionInput.value}'`);
    }

    function updateQuestionInput(newQuestion: string): void {
      questionInput.value = newQuestion;
    }

    return () => (
      <DemoWrapper>
        <InitialForm language={"en"} inputValue={questionInput.value} updateInputValue={updateQuestionInput} submitInitialQuestion={submitInitialQuestion} showAiDataProtectionDisclaimer={false} />
      </DemoWrapper>
    );
  },
});

const DemoTrending = defineComponent({
  setup() {
    function chooseTrendingQuestion(question: string): void {
      console.log(`'chooseTrendingQuestion' event fired. Selected question: '${question}'`);
    }

    return () => (
      <DemoWrapper>
        <TrendingQuestions chooseTrendingQuestion={chooseTrendingQuestion} />
      </DemoWrapper>
    );
  },
});

const DemoSelectedQuestion = defineComponent({
  setup() {
    return () => (
      <DemoWrapper>
        <SelectedQuestion question="How to be a better leader?" isRelatedQuestion={false} detectedLanguageLabel={"Here we inform about detected languages."} />
      </DemoWrapper>
    );
  },
});

const DemoSelectedRelatedQuestion = defineComponent({
  setup() {
    return () => (
      <DemoWrapper>
        <SelectedQuestion question="What are some ways to build trust and rapport with my team?" isRelatedQuestion={true} detectedLanguageLabel={null} />
      </DemoWrapper>
    );
  },
});

const DemoLoader = defineComponent({
  setup() {
    return () => (
      <DemoWrapper>
        <AnswerCardPlaceholder />
      </DemoWrapper>
    );
  },
});

const DemoErrors = defineComponent({
  setup() {
    return () => (
      <div>
        <h4 class={DEMO_TITLE_CLASSES}>General</h4>
        <DemoWrapper>
          <ErrorMessage status={"ERROR_GENERAL"} question={DUMMY_CONTENT.question} questionUuid={DUMMY_CONTENT.questionUuid} />
        </DemoWrapper>
        <h4 class={DEMO_TITLE_CLASSES}>No Answer</h4>
        <DemoWrapper>
          <ErrorMessage status={"ERROR_NO_ANSWER"} question={DUMMY_CONTENT.question} questionUuid={DUMMY_CONTENT.questionUuid} />
        </DemoWrapper>
      </div>
    );
  },
});

const DemoAnswer = defineComponent({
  setup() {
    return () => (
      <DemoWrapper>
        <AnswerCard answer={DUMMY_CONTENT.answer} contextDocs={DUMMY_CONTENT.contextDocs} questionUuid={DUMMY_CONTENT.questionUuid} />
      </DemoWrapper>
    );
  },
});

const DemoFeedback = defineComponent({
  setup() {
    const feedbackIsPositive = ref<boolean | null>(null);

    function giveFeedback(questionUuid: string, isPositive: boolean): void {
      feedbackIsPositive.value = isPositive;
      console.log(`'giveFeedback' event fired.`, { questionUuid, isPositive });
    }

    return () => (
      <DemoWrapper>
        <Feedback question={DUMMY_CONTENT.question} questionUuid={DUMMY_CONTENT.questionUuid} feedbackIsPositive={feedbackIsPositive.value} giveFeedback={giveFeedback} />
      </DemoWrapper>
    );
  },
});

const DemoReferences = defineComponent({
  setup() {
    const content = ref<SimplifiedHistoryEntry>(DUMMY_CONTENT);
    const bookmarks = ref<Map<number, boolean>>(new Map());

    function toggleBookmark(dataId: number, bookmarked: boolean): void {
      const updatedMap = new Map(bookmarks.value);
      updatedMap.set(dataId, bookmarked);
      bookmarks.value = updatedMap;
    }

    function summaryIsBookmarked(dataId: number): boolean {
      return bookmarks.value.get(dataId) ?? false;
    }

    return () => (
      <DemoWrapper>
        <References contextDocs={content.value.contextDocs} answer={content.value.answer} questionUuid={content.value.questionUuid} toggleBookmark={toggleBookmark} summaryIsBookmarked={summaryIsBookmarked} />
      </DemoWrapper>
    );
  },
});

const DemoRelatedActionables = defineComponent({
  setup() {
    return () => (
      <DemoWrapper>
        <RelatedActionables relatedActionables={DUMMY_CONTENT.relatedActionables} questionUuid={DUMMY_CONTENT.questionUuid} />
      </DemoWrapper>
    );
  },
});

const DemoRelatedQuestions = defineComponent({
  setup() {
    function chooseRelatedQuestion(question: string): void {
      console.log(`'chooseRelatedQuestion' event fired. Selected question: '${question}'`);
    }

    return () => (
      <DemoWrapper>
        <RelatedQuestions relatedQuestions={DUMMY_CONTENT.relatedQuestions} chooseRelatedQuestion={chooseRelatedQuestion} />
      </DemoWrapper>
    );
  },
});

const DemoHistoryEntry = defineComponent({
  setup() {
    const entry = ref<QuestionAnswer>({
      question: DUMMY_CONTENT.question,
      questionUuid: DUMMY_CONTENT.questionUuid,
      detectedLanguageLabel: null,
      answer: DUMMY_CONTENT.answer,
      contextDocs: DUMMY_CONTENT.contextDocs,
      relatedActionables: DUMMY_CONTENT.relatedActionables,
      relatedQuestions: DUMMY_CONTENT.relatedQuestions,
    });
    const feedbackIsPositive = ref<boolean | null>(null);
    const bookmarks = ref<Map<number, boolean>>(new Map());

    function giveFeedback(questionUuid: string, isPositive: boolean): void {
      feedbackIsPositive.value = isPositive;
      console.log(`'giveFeedback' event fired.`, { questionUuid, isPositive });
    }

    function summaryIsBookmarked(dataId: number): boolean {
      return bookmarks.value.get(dataId) ?? false;
    }

    function toggleBookmark(dataId: number, bookmarked: boolean): void {
      const updatedMap = new Map<number, boolean>();
      updatedMap.set(dataId, bookmarked);
      bookmarks.value = updatedMap;
    }

    return () => (
      <DemoWrapper>
        <HistoryEntry entry={entry.value} giveFeedback={giveFeedback} toggleBookmark={toggleBookmark} feedbackIsPositive={() => feedbackIsPositive.value} summaryIsBookmarked={summaryIsBookmarked} />
      </DemoWrapper>
    );
  },
});

/**
 * Render page
 */

export const GetabstractAiDemo = defineComponent({
  setup() {
    return () => (
      <div>
        <div class="container">
          <h2 class={DEMO_TITLE_CLASSES}>Widget</h2>
        </div>
        <DemoWrapper>
          <Widget />
        </DemoWrapper>
        <div class="container">
          <h2 class={DEMO_TITLE_CLASSES}>Components</h2>
          <h3 class={DEMO_TITLE_CLASSES}>Input</h3>
          <DemoInput />
          <h3 class={DEMO_TITLE_CLASSES}>Trending</h3>
          <DemoTrending />
          <h3 class={DEMO_TITLE_CLASSES}>Selected Question</h3>
          <DemoSelectedQuestion />
          <h3 class={DEMO_TITLE_CLASSES}>Selected Related Question</h3>
          <DemoSelectedRelatedQuestion />
          <h3 class={DEMO_TITLE_CLASSES}>Loader</h3>
          <DemoLoader />
          <h3 class={DEMO_TITLE_CLASSES}>Errors</h3>
          <DemoErrors />
          <h3 class={DEMO_TITLE_CLASSES}>Answer</h3>
          <DemoAnswer />
          <h3 class={DEMO_TITLE_CLASSES}>Feedback</h3>
          <DemoFeedback />
          <h3 class={DEMO_TITLE_CLASSES}>References</h3>
          <DemoReferences />
          <h3 class={DEMO_TITLE_CLASSES}>Related Actionables</h3>
          <DemoRelatedActionables />
          <h3 class={DEMO_TITLE_CLASSES}>Related Questions</h3>
          <DemoRelatedQuestions />
          <h3 class={DEMO_TITLE_CLASSES}>History Entry</h3>
          <DemoHistoryEntry />
          <style>{`
            :where(.demo-title):hover + .demo-wrapper { 
              outline: 1px solid hotpink; 
            } 
            
            .demo-title { 
              background-color: var(--ga-primary-200); 
            }
          `}</style>
        </div>
      </div>
    );
  },
});
