<template>
  <a :href="href" :target="linkTarget" class="channel-card-mini">
    <img :src="coverUrl" alt="" class="channel-card-mini__img lazyload" />
    <div class="channel-card-mini__title">
      {{ channel.title }}
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { ChannelListView } from "@generated/model/channelListView";
import "./_channel-card-mini.scss";

export default defineComponent({
  props: {
    channel: {
      type: Object as PropType<ChannelListView>,
      required: true,
    },
    linkTarget: {
      type: String,
    },
  },
  setup(props) {
    const href = "/channels/" + props.channel.name + "/" + props.channel.channelId;
    const coverUrl = props.channel.coverUri + "?s=L";
    return {
      href,
      coverUrl,
    };
  },
});
</script>
