import type { DeepReadonly } from "vue";
import type { ComponentActions, FormStepExtended, OverlayStatus } from "@/components/actionable/actionable-page/actionablePageStore";
import { assert, assertIsDefined } from "@utils/assertion";
import type { TranslateFunction } from "@/i18n/i18nSetup";
import type { GaVueComponent } from "@/common/vueUtils";
import { PeerInsightFeedbackForm } from "@/components/actionable/actionable-page/PeerInsightFeedbackForm";
import { LoadingAnimation } from "@/components/actionable/actionable-page/LoadingAnimation";
import { PdfPreview } from "@/components/actionable/actionable-page/PdfPreview";
import type { ActionablePageProperties } from "@generated/model/actionablePageProperties";

function isSuccessPath(formSteps: DeepReadonly<FormStepExtended[]>): boolean {
  const applicationStep = formSteps.find((step) => step.question.key === "APPLICATION");
  assertIsDefined(applicationStep);
  return applicationStep.selectedScore !== null && applicationStep.selectedScore > 3;
}

export function PeerInsightOverlayChildren(props: {
  displayMode: OverlayStatus;
  t: TranslateFunction;
  formSteps: DeepReadonly<FormStepExtended[]>;
  currentFormStep: DeepReadonly<FormStepExtended> | undefined;
  actions: ComponentActions;
  progress: number;
  feedbackLength: number;
  actionableId: bigint;
  isMapp: boolean;
  properties: DeepReadonly<ActionablePageProperties>;
}): GaVueComponent | null {
  const currentFormStepTitle =
    !isSuccessPath(props.formSteps) && props.currentFormStep?.question.key === "TRYOUT"
      ? props.t("actionable:peerinsight.form.question.tryout.difficultiesPath")
      : props.t("actionable:peerinsight.form.question." + props.currentFormStep?.question.key.toLowerCase());
  switch (props.displayMode) {
    case "FORM":
      if (props.currentFormStep !== undefined) {
        return (
          <PeerInsightFeedbackForm
            currentFormStep={props.currentFormStep}
            t={props.t}
            onUpdateValue={props.actions.onUpdateFormValue}
            navigateForm={props.actions.navigateForm}
            selectSuggestedAnswer={props.actions.onSelectPredefinedAnswer}
            submitForm={props.actions.submitForm}
            progress={props.progress}
            amountOfPeers={props.feedbackLength}
            formStepTitle={currentFormStepTitle}
          />
        );
      }
      return null;
    case "ANIMATION":
      return (
        <div class="mt-4 peerinsight-overlay__animation-wrap">
          <LoadingAnimation t={props.t} />
        </div>
      );
    case "CERTIFICATE":
      assert(props.properties.peerInsightProvidedAt !== null);
      return (
        <PdfPreview
          peerInsightProvidedAt={props.properties.peerInsightProvidedAt}
          actionableTitle={props.properties.actionableTitle}
          customerId={props.properties.customerId}
          certificateId={props.properties.certificateId}
          t={props.t}
          closeForm={() => props.actions.toggleOverlay("CLOSED")}
          actionableId={props.actionableId}
          isMapp={props.isMapp}
        />
      );
    case "CLOSED":
      return null;
  }
}
